import { styled } from '@mui/material/styles';
const Spacer = styled('span')`
  flex-grow: 1;
`;

function FlexRowSpacer() {
  return <Spacer />;
}

export default FlexRowSpacer;
